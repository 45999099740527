import React from "react";
import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";
import { MyTitle, SectionWrapper, TitleStyled } from "./styled";
import { makeStyles } from "@material-ui/core";
import Box from "components/layout/Box";
import { Text1 } from "components/core/Typography/text";
import { Card1 } from "views/Cards/Cards";
import { Icon, icons } from "components/icons/styled";
import { SecondaryBadge } from "components/typography/styled";
import { media } from "views/dataMedia";

const useStyles = makeStyles(officeStyle);

export default function SectionMedia() {
  const classes = useStyles();
  console.log('{ media }', media)
  return (
    <SectionWrapper>
      <Box>
        <TitleStyled>
          AlianZas en los medios
        </TitleStyled>
      </Box>
      <Box
        split={12}>
          {media.map(({ href, title, text, icon, src }) => (
            <a href={href} key={href}>
              <Card1 classes={classes} src={src}>
                <MyTitle><SecondaryBadge ><Icon icon={icon} /></SecondaryBadge>{title}</MyTitle>
                { text && <Text1>{text} <Icon icon={icons.right} /></Text1>}
              </Card1>
            </a>
          ))}
      </Box>
    </SectionWrapper>
  );
}
