/*eslint-disable*/
import React from "react";

import { PresentationPageWrapper } from "./styled";
import SectionLinks from "./Sections/SectionLinks";
import SectionMedia from "./Sections/SectionMedia";
import SectionFooter from "./Sections/SectionFooter";


export default function MediaPage() {
  return (
    <PresentationPageWrapper>
      <SectionLinks logo selected='/medios' />
      <SectionMedia />
      <SectionFooter hideButton />
    </PresentationPageWrapper>
  );
}
